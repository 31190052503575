export const FRAME_DATES = {
	0: '14 Feb',
	1: '15 Feb',
	2: '16 Feb',
	3: '17 Feb',
	4: '18 Feb',
	5: '19 Feb',
	6: '20 Feb',
	7: '21 Feb',
	8: '22 Feb',
	9: '23 Feb',
	10: '24 Feb',
	11: '25 Feb',
	12: '26 Feb',
	13: '27 Feb',
	14: '28 Feb',
	15: '1 Mar',
	16: '2 Mar',
	17: '3 Mar',
	18: '4 Mar',
	19: '5 Mar',
	20: '6 Mar',
	21: '7 Mar',
	22: '8 Mar',
	23: '9 Mar',
	24: '10 Mar',
	25: '11 Mar',
	26: '12 Mar',
	27: '13 Mar',
	28: '14 Mar',
	29: '15 Mar',
	30: '16 Mar',
	31: '17 Mar',
	32: '18 Mar',
	33: '19 Mar',
	34: '20 Mar',
	35: '21 Mar',
	36: '22 Mar',
	37: '23 Mar',
	38: '24 Mar',
	39: '25 Mar',
	40: '26 Mar',
	41: '27 Mar',
	42: '28 Mar',
	43: '29 Mar',
	44: '30 Mar',
	45: '31 Mar',
	46: '1 Apr',
	47: '2 Apr',
	48: '3 Apr',
	49: '4 Apr',
	50: '5 Apr',
	51: '6 Apr',
	52: '7 Apr',
	53: '8 Apr',
	54: '9 Apr',
	55: '10 Apr',
	56: '11 Apr',
	57: '12 Apr',
	58: '13 Apr',
	59: '14 Apr',
	60: '15 Apr',
	61: '16 Apr',
	62: '17 Apr',
	63: '18 Apr',
	64: '19 Apr',
	65: '20 Apr',
	66: '21 Apr',
	67: '22 Apr',
	68: '23 Apr',
	69: '24 Apr',
	70: '25 Apr',
	71: '26 Apr',
	72: '27 Apr',
	73: '28 Apr',
	74: '29 Apr',
	75: '30 Apr',
	76: '1 Mei',
	77: '2 Mei',
	78: '3 Mei',
	79: '4 Mei',
	80: '5 Mei',
	81: '6 Mei',
	82: '7 Mei',
	83: '8 Mei',
	84: '9 Mei',
	85: '10 Mei',
	86: '11 Mei',
	87: '12 Mei',
	88: '13 Mei',
	89: '14 Mei',
	90: '15 Mei',
	91: '16 Mei',
	92: '17 Mei',
	93: '18 Mei',
	94: '19 Mei',
	95: '20 Mei',
	96: '21 Mei',
	97: '22 Mei',
	98: '23 Mei',
	99: '24 Mei'
}