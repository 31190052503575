const defaultTagOptions = {
	'hashtags': [
		// 'foryou',
		// 'football',
		// 'funny',
		// 'nederland',
		// 'weer',		
	],
	'creators': [
		// 'meesterjesper',
		// 'fatoemakers',
		// 'mr.mask.live2.0'		
	]
}

export default defaultTagOptions;